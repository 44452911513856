import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { concatPagination } from '@apollo/client/utilities';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { getStoredAccessToken } from '../common/utils/authHelpers';
import { GRAPH_CMS_ASSET, GRAPH_CMS_CLIENT_NAME } from '../common/constants';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const GRAPHQL_URL = `${process.env.NEXT_PUBLIC_API_URL}/api/graphql`;

const graphCmsLink = from([
  new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPH_CMS_API_URL,
  }),
]);

const assetsLink = from([
  new HttpLink({
    uri: `/api/cms/assets/delete`,
    credentials: 'include',
  }),
]);

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
});

const authLink = setContext(async (_, prevState) => {
  return {
    headers: {
      ...prevState.headers,
      authorization: getStoredAccessToken()
        ? `Bearer ${getStoredAccessToken()}`
        : '',
    },
  };
});

const normalLink = from([
  authLink,
  // errorLink,
  // retryLink,
  httpLink,
]);

const link = ApolloLink.split(
  (operation) => {
    return operation.getContext().clientName === GRAPH_CMS_ASSET;
  },
  assetsLink,
  ApolloLink.split(
    (operation) => {
      return operation.getContext().clientName === GRAPH_CMS_CLIENT_NAME;
    },
    graphCmsLink,
    normalLink
  )
);

let apolloClient;

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
