import { EnumAuthCookies } from '../enums/generalEnums';
import nookies, { destroyCookie } from 'nookies';
import { ApolloClient } from '@apollo/client';
import {
  NextPageContext,
  NextApiRequest,
  NextApiResponse,
  NextApiHandler,
} from 'next';
import { initializeApollo } from '../../apollo/apollo-client';
import { isProduction } from './helpers';
import jwt from 'jsonwebtoken';

export const getStoredAccessToken = (ctx?: NextPageContext) =>
  nookies.get(ctx || {})[EnumAuthCookies.AccessToken];

const removeStoredTokens = (ctx?: NextPageContext) => {
  const context = ctx || {};

  nookies.destroy(context, EnumAuthCookies.AccessToken, {
    path: '/',
  });
  nookies.destroy(context, EnumAuthCookies.RefreshToken, {
    path: '/',
  });
  nookies.destroy(context, 'userId', {
    path: '/',
  });
  // destroyCookie(ctx, EnumAuthCookies.AccessToken, {
  //   path: '/',
  // });
  // destroyCookie(ctx, EnumAuthCookies.RefreshToken, {
  //   path: '/',
  // });
};

export const storeNewTokens = (
  accessToken: string,
  refreshToken: string,
  expiresIn?: number | null,
  refreshExpiresIn?: number | null
): void => {
  nookies.set({}, EnumAuthCookies.AccessToken, accessToken, {
    maxAge: expiresIn,
    path: '/',
    sameSite: 'strict',
    secure: isProduction,
  });
  nookies.set({}, EnumAuthCookies.RefreshToken, refreshToken, {
    maxAge: refreshExpiresIn,
    path: '/',
    sameSite: 'strict',
    secure: isProduction,
  });
};

export const signIn = async (
  accessToken: string | null,
  refreshToken: string | null,
  expiresIn?: number | null,
  refreshExpiresIn?: number | null,
  client?: ApolloClient<any>
): Promise<void> => {
  if (accessToken && refreshToken) {
    storeNewTokens(accessToken, refreshToken, expiresIn, refreshExpiresIn);
  }

  // await client?.resetStore();
};

export const logOut = async (routeToLogin): Promise<void> => {
  const apolloClient = initializeApollo();

  removeStoredTokens();

  await apolloClient.clearStore();
  routeToLogin();
};

export const getStoredUserId = (ctx?: NextPageContext) =>
  nookies.get(ctx || {})['userId'];

export function withAuth(handler: NextApiHandler): NextApiHandler {
  return async (req: NextApiRequest, res: NextApiResponse) => {
    const cookies = nookies.get({ req });
    const accessToken = cookies.accessToken;

    if (!accessToken) {
      return res.status(401).json({ error: 'Unauthorized' });
    }

    const publicKey = process.env.JWT_ACCESS_PUBLIC_KEY;
    if (!publicKey) {
      console.error('JWT public key is missing');
      return res.status(500).json({ error: 'Internal server error' });
    }

    try {
      jwt.verify(accessToken, publicKey, { algorithms: ['RS256'] });
      return handler(req, res);
    } catch (error) {
      console.error('JWT Verification Error:', error);
      return res.status(401).json({ error: 'Unauthorized: Invalid token' });
    }
  };
}
