import { PhaseStage, UserRole } from '../../../__generated__/globalTypes';
import { EnumLocalStorage } from '../enums/generalEnums';
import { STATUS } from 'react-joyride';
import { NextApiRequest } from 'next';

export const isAdmin = (user) =>
  user?.role === UserRole.ADMIN || user?.role === UserRole.SUPER_ADMIN;

export const isProduction = process.env.NEXT_PUBLIC_NODE_ENV === 'production';
export const isStaging = process.env.NEXT_PUBLIC_NODE_ENV === 'staging';
export const isDev = process.env.NEXT_PUBLIC_NODE_ENV === 'localhost';

export const setLastVisitedPhase = (phase: PhaseStage) =>
  window.localStorage.setItem(EnumLocalStorage.LastVisitedDiaryStage, phase);

export const getLastVisitedPhase = (): string =>
  window.localStorage.getItem(EnumLocalStorage.LastVisitedDiaryStage);

export const setSeenChatbot = () =>
  window.localStorage.setItem(EnumLocalStorage.SeenChatbot, 'true');

export const getSeenChatbot = (): string =>
  window.localStorage.getItem(EnumLocalStorage.SeenChatbot);

export const getStoredMessages = (userId): string =>
  window.localStorage.getItem(`copilotkit-messages-${userId}`);

export const getStoredFeedback = (userId): string =>
  window.localStorage.getItem(`message-feedback-${userId}`);

export const getTours = (): string[] =>
  JSON.parse(window?.localStorage?.getItem('toursTaken') || '[]');

export const setTour = (prevTours: string[], tour: string) =>
  localStorage.setItem('toursTaken', JSON.stringify([...prevTours, tour]));

export const checkTourFinish = (status: string): boolean => {
  const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

  if (finishedStatuses.includes(status)) {
    return true;
  }
  return false;
};

export const sanitizeInput = (value) => {
  return value.replace(/^\s*'+\s*|\s*'+\s*$/g, '').trim();
};

export const getRawBody = (req: NextApiRequest): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const chunks: Buffer[] = [];
    req.on('data', (chunk: Buffer) => {
      chunks.push(chunk);
    });
    req.on('end', () => {
      resolve(Buffer.concat(chunks));
    });
    req.on('error', (err) => {
      reject(err);
    });
  });
};

export async function proxyRequest(
  url: string,
  method: string,
  headers: Record<string, string>,
  body: Buffer | ReadableStream | null | string
) {
  const response = await fetch(url, {
    method,
    headers,
    body,
  });
  return response;
}

export const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content);
};
